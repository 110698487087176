const Group = () =>
  import( /* webpackChunkName: "group-app" */ "@/pages/webapp/groups/Group");
const Products = () =>
  import( /* webpackChunkName: "group-app" */ "@/pages/webapp/products/Products");
const Investment = () =>
  import( /* webpackChunkName: "group-app" */ "@/pages/webapp/investment/Investment");
const Home = () =>
  import( /* webpackChunkName: "group-site" */ "@/pages/website/Home");
const Withdrawal = () =>
  import( /* webpackChunkName: "group-site" */ "@/pages/webapp/withdrawal/Withdrawal");
const TransferToCustomer = () => import( /* webpackChunkName: "group-dashboard" */ "@/pages/webapp/transfer/ToCustomer");
const TransferToProduct = () => import( /* webpackChunkName: "group-dashboard" */ "@/pages/webapp/transfer/ToProduct");
const Dashboard = () =>
  import( /* webpackChunkName: "group-dashboard" */ "@/pages/webapp/dashboard/Dashboard");
const Details = () => import( /* webpackChunkName: "group-dashboard" */ "@/pages/webapp/dashboard/Details");
const Users = () =>
  import( /* webpackChunkName: "group-dashboard"  */ "@/pages/webapp/groups/users");
const Products_two = () =>
  import( /* webpackChunkName: "group-site" */ "@/pages/webapp/products/products2");
const Settings = () =>
  import( /* webpackChunkName: "group-settings" */ "@/pages/webapp/settings/Settings");
const Withdrawal_two = () =>
  import( /* webpackChunkName: "group-settings" */ "@/pages/webapp/withdrawal/withdrawal2");

  const reports = () =>
  import( /* webpackChunkName: "group-reports" */ "@/pages/webapp/reports/reports");
const Simulate = () =>
  import( /* webpackChunkName: "group-reports" */ "@/pages/webapp/investment/simulate");

const Events = () =>
  import( /* webpackChunkName: "group-invest" */ "@/pages/webapp/groups/eventInvest");
const Family = () =>
  import( /* webpackChunkName: "group-invest" */ "@/pages/webapp/groups/familyInvest");
const Grouping = () =>
  import( /* webpackChunkName: "group-invest" */ "@/pages/webapp/groups/groupInvest");
const Admin = () =>
  import( /* webpackChunkName: "group-invest" */ "@/pages/webapp/groups/adminInvest");

const signin = () =>
  import( /* webpackChunkName: "group-quickload" */ "@/pages/website/signin");

const signup = () =>
  import( /* webpackChunkName: "group-quickload" */ "@/pages/website/signup");

const support = () =>
  import( /* webpackChunkName: "group-quickload" */ "@/pages/website/support");

const Indexx = () =>
  import( /* webpackChunkName: "group-begins" */ "@/pages/webapp/dashboard/Index");
const Resend = () =>
  import( /* webpackChunkName: "group-begins" */ "@/pages/website/resend-activation");
const activate = () =>
  import( /*webpackChunkName: "group-site" */ "@/pages/website/activate");
const forgotPassword = () =>
  import( /*webpackChunkName: "group-home" */ "@/pages/website/forgotPassword");
const confirmation = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/website/confirmation");
const changePassword = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/website/changePassword");
const emmanuel_ajala_refKbox = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/emmanuel_ajala_refKbox");
  const florence_ofuru_refKbox = () =>
    import( /*webpackChunkName: "group-home"*/ "@/pages/referals/florence_ofuru_refKbox");
 const mayowa_oladipupo_radixNg = () =>
   import( /*webpackChunkName: "group-home"*/ "@/pages/referals/mayowa_oladipupo_radixNg");
const lasisi_suleman_radixNg = () =>
     import( /*webpackChunkName: "group-home"*/ "@/pages/referals/lasisi_suleman_radixNg");
const dare_omosowon_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/dare_omosowon_radixNg");
const tolani_olubajo_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/tolani_olubajo_radixNg");
const josephine_ajirogbagoma_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/josephine_ajirogbagoma_radixNg");
const churchhill_imosemi_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/churchhill_imosemi_radixNg");
const tope_fakayode_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/tope_fakayode_radixNg");
const adegbite_gabriel_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/adegbite_gabriel_radixNg");
const nkemdili_malizu_radixNg = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/nkemdili_malizu_radixNg");

// ajteam
const babs_ajala_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/babs_ajala_AjTeam");
const join_theirsagriglobe_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/join_theirsagriglobe_AjTeam");
const olateju_ogunnusi_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/olateju_ogunnusi_AjTeam");
const layinka_yusuf_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/layinka_yusuf_AjTeam");
const precious_alao_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/precious_alao_AjTeam");
const tolulope_david_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/tolulope_david_AjTeam");
const omolara_ibraheem_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/omolara_ibraheem_AjTeam");
const davulge_AjTeam = () =>
  import( /*webpackChunkName: "group-home"*/ "@/pages/referals/davulge_AjTeam");


const ReverseSimulate = () =>
  import( /*webpackChunkName: "group-begin" */ "@/pages/webapp/investment/reverseSimulate");
const JoinGroup = () =>
  import( /*webpackChunkName: "group-begin"*/ "@/pages/webapp/groups/joinGroup");
const SubUsers = () =>
  import( /*webpackChunkName: "grou-begin" */ "@/pages/webapp/groups/subusers");
const ValidateGroup = () =>
  import( /*webpackChunkName: "grou-begin" */ "@/pages/webapp/groups/validateGroup");
const AuthSubUser = () =>
  import( /*webpackChunkName: "grou-begin" */ "@/pages/webapp/groups/authSubuser");

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/signin",
      name: "Signin",
      component: signin
    },
    {
      path: "/signup",
      name: "Signup",
      component: signup
    },
    {
      path: "/support",
      name: "support",
      component: support
    },
    {
      path: "/confirmation",
      name: "confirmation",
      component: confirmation
    },
    {
      path: "/change_password",
      name: "changePassword",
      component: changePassword
    },
    {
      path: "/auth-subuser",
      name: "AuthSubUser",
      component: AuthSubUser
    },
    {
      path: "/resend-activation",
      name: "Resend",
      component: Resend
    },
    {
      path: "/activate",
      name: "Activate",
      component: activate
    },
    {
      path: "/forgot_password",
      name: "forgotPassword",
      component: forgotPassword
    },

    {
      path: "/referals/emmanuel_ajala_refKbox",
      name: "emmanuel_ajala_refKbox",
      component: emmanuel_ajala_refKbox
    },

    {
         path: "/referals/florence_ofuru_refKbox",
         name: "florence_ofuru_refKbox",
         component: florence_ofuru_refKbox
    },

       {
         path: "/referals/mayowa_oladipupo_radixNg",
         name: "mayowa_oladipupo_radixNg",
         component: mayowa_oladipupo_radixNg
       },
      
     {
       path: "/referals/lasisi_suleman_radixNg",
       name: "lasisi_suleman_radixNg",
       component: lasisi_suleman_radixNg
     },

        {
          path: "/referals/dare_omosowon_radixNg",
          name: "dare_omosowon_radixNg",
          component: dare_omosowon_radixNg
        },

     {
       path: "/referals/tolani_olubajo_radixNg",
       name: "tolani_olubajo_radixNg",
       component: tolani_olubajo_radixNg
     },

   {
            path: "/referals/josephine_ajirogbagoma_radixNg",
            name: "josephine_ajirogbagoma_radixNg",
            component: josephine_ajirogbagoma_radixNg
   },

           {
             path: "/referals/churchhill_imosemi_radixNg",
             name: "churchhill_imosemi_radixNg",
             component: churchhill_imosemi_radixNg
           },

              {
                path: "/referals/tope_fakayode_radixNg",
                name: "tope_fakayode_radixNg",
                component: tope_fakayode_radixNg
              },

                 {
                   path: "/referals/adegbite_gabriel_radixNg",
                   name: "adegbite_gabriel_radixNg",
                   component: adegbite_gabriel_radixNg
                 },

                    {
                      path: "/referals/nkemdili_malizu_radixNg",
                      name: "nkemdili_malizu_radixNg",
                      component: nkemdili_malizu_radixNg
                    },
    
      {
        path: "/referals/babs_ajala_AjTeam",
        name: "babs_ajala_AjTeam",
        component: babs_ajala_AjTeam
      },
      {
          path: "/referals/join_theirsagriglobe_AjTeam",
          name: "join_theirsagriglobe_AjTeam",
          component: join_theirsagriglobe_AjTeam
      },
      {
          path: "/referals/olateju_ogunnusi_AjTeam",
          name: "olateju_ogunnusi_AjTeam",
          component: olateju_ogunnusi_AjTeam
      }, 
      {
          path: "/referals/layinka_yusuf_AjTeam",
          name: "layinka_yusuf_AjTeam",
          component: layinka_yusuf_AjTeam
      },
      {
        path: "/referals/precious_alao_AjTeam",
        name: "precious_alao_AjTeam",
        component: precious_alao_AjTeam
      }, {
        path: "/referals/tolulope_david_AjTeam",
        name: "tolulope_david_AjTeam",
        component: tolulope_david_AjTeam
      }, {
        path: "/referals/omolara_ibraheem_AjTeam",
        name: "omolara_ibraheem_AjTeam",
        component: omolara_ibraheem_AjTeam
      }, {
        path: "/referals/davulge_AjTeam",
        name: "davulge_AjTeam",
        component: davulge_AjTeam
      },
   
    {
      path: "/join-group/:group_id/user/:user_id",
      name: "JoinGroup",
      component: JoinGroup
    },
    {
      path: "/index",
      name: "Index",
      component: Indexx,
      meta: {
        requiresAuth: true
      },
      children: [{
          path: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "investment",
          component: Investment,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "groups",
          component: Group,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "group",
          component: Grouping,
          meta: {
            requiresAuth: true
          }
        },
        // {
        //   path: "reports",
        //   component: Reports,
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        {
          path: "family",
          component: Family,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "event",
          component: Events,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin",
          component: Admin,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "settings",
          name: "settings",
          component: Settings,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "withdraw",
          name: "withdraw",
          component: Withdrawal,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "withdrawal2",
          name: "withdrawal2",
          component: Withdrawal_two,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "transfer/customer",
          name: "transferToCustomer",
          component: TransferToCustomer,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "transfer/product",
          name: "transferToProduct",
          component: TransferToProduct,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "details",
          name: "details",
          component: Details,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "products",
          name: "products",
          component: Products,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "products_two",
          component: Products_two,
          name: "products_two",
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "users",
          name: "users",
          component: Users,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "subusers",
          name: "SubUsers",
          component: SubUsers,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "simulate",
          name: "simulate",
          component: Simulate,
          meta: {
            requiresAuth: true
          }
        },

           {
          path: "reports",
          name: "reports",
          component: reports,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "reverse-simulate",
          name: "reverseSimulate",
          component: ReverseSimulate,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "validate-group",
          name: "validateGroup",
          component: ValidateGroup,
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (Vue.prototype.$auth.authenticated) next();
    else next("/");
  } else {
    if (Vue.prototype.$auth.authenticated) next("/index/dashboard");
    else next();
  }
});

export default router;
