export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_TENORS = "UPDATE_TENORS";
export const UPDATE_KOLO_GROUPS = "UPDATE_KOLO_GROUPS";
export const UPDATE_FAMILY_GROUPS = "UPDATE_FAMILY_GROUPS";
export const UPDATE_EVENT_GROUPS = "UPDATE_EVENT_GROUPS";
export const UPDATE_ADMIN_GROUPS = "UPDATE_ADMIN_GROUPS";
export const UPDATE_GROUP_VOTES = "UPDATE_GROUP_VOTES";
export const UPDATE_TENOR_VOTES = "UPDATE_TENOR_VOTES";
export const UPDATE_ALL_SUBUSERS = "UPDATE_ALL_SUBUSERS";
export const UPDATE_GROUP_DASHBOARD = "UPDATE_GROUP_DASHBOARD";
